export default () => {
  apos.util.widgetPlayers['contact-us'] = {
    selector: '[data-contact-us-widget]',
    player: function(el) {
      function sendForm(e) {
        const emailSub = document.getElementById("mailSubj").value;
        const emailBody = document.getElementById("mailMess").value;
        location.href = "mailto:info@partacar.com?subject=" + emailSub + '&body=' + emailBody;

        document.getElementById("mailSubj").value = '';
        document.getElementById("mailMess").value = '';
        document.getElementById("mailName").value = '';
        document.getElementById("mailInfo").value = '';
        document.getElementById("mailAddress").value = '';
      }
      const sendFormBtn = document.querySelector('.js-send-form-btn');
      sendFormBtn.addEventListener('click', sendForm);
    }
  };
};
