import axios from "axios";

const config = Object.assign({}, window.erpPortalApiConfig);

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    return Promise.reject(error);
  }
);

let initialized = false;

const init = async () => {
  if (initialized) return;
  initialized = true;

  if (localStorage.getItem('erpPortalApiAuthToken')) {
    try {
      const response = await _axios({
        method: 'GET',
        url: '/v1/user/info',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('erpPortalApiAuthToken')}`
        }
      });

      if (response.status == 200) {
        const url = localStorage.getItem('erpPortalApiAuthReturnUrl');
        localStorage.removeItem('erpPortalApiAuthReturnUrl');

        GnAdditionals.Notification({
          title: 'Already signed in. Redirecting...',
          type: 'success'
        });

        setTimeout(() => window.location.href = url || '/', 3000);
      }
    } catch (error) {
      localStorage.removeItem('erpPortalApiAuthToken');
      localStorage.removeItem('erpPortalUsername');
    }
  }

  const form = document.getElementById('signin-widget-form');

  form.addEventListener('submit', async e => {
    e.preventDefault();

    const email = form.elements['email'].value;
    const password = form.elements['password'].value;
    const button = document.getElementById('signin-widget-btn');
    const loading = document.querySelector('.login-page .loading');
    button.disabled = true;
    loading.style.display = 'block';

    try {
      const response = await _axios.post('/v1/auth/signin', {
        cid: window.erpPortalApiCompany,
        email,
        password,
        type: 'contact',
        source: 'Portal'
      });

      if (response.data && response.data.data && response.data.data.status) {
        localStorage.setItem('erpPortalApiAuthToken', response.data.data.token);
        localStorage.setItem('erpPortalUsername', `${response.data.data.first_name} ${response.data.data.last_name || ''}`);

        let shoppingCart = localStorage.getItem('erpPortalShoppingCart');
        if (shoppingCart) {
          shoppingCart = JSON.parse(shoppingCart);

          if (Array.isArray(shoppingCart) && shoppingCart.length > 0) {
            for (let item of shoppingCart) {
              await _axios({
                method: 'POST',
                url: '/v1/quote/line/add',
                data: {
                  parent_module: item.parent_module,
                  parent_entity_id: item.parent_entity_id,
                  parent_line_id: item.parent_line_id,
                  quantity: item.quantity,
                  notes: item.notes,
                },
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('erpPortalApiAuthToken')}`
                }
              }).catch(() => {});
            }
          }

          localStorage.removeItem('erpPortalShoppingCart');
        }

        const url = localStorage.getItem('erpPortalApiAuthReturnUrl');
        localStorage.removeItem('erpPortalApiAuthReturnUrl');
        window.location.href = url || '/';
      } else {
        const title = response && response.data && response.data.data && response.data.data.message;
        throw { title };
      }
    } catch (e) {
      const title = e && e.title;
      GnAdditionals.Notification({
        title: title || 'Something went wrong please try again',
        type: 'error'
      });
      button.disabled = false;
      loading.style.display = 'none';
    }
  });
};

export default () => {
  apos.util.widgetPlayers.signin = {
    selector: '[data-signin-widget]',
    player: function (el) {
      if (apos.adminBar) {
        const form = document.getElementById('signin-widget-form');
        form.addEventListener('submit', e => {
          e.preventDefault();
          GnAdditionals.Notification({
            title: 'Disable admin mode to activate this widget',
            type: 'error'
          });
        });
        return;
      }

      init();
    }
  };
};
